<template>
    <div class="friends-tile">
        <el-row type="flex" justify="left">
            <el-col :xs="{span: 8}" :sm="{span: 8}">
                <el-image :src="avatar" class="user-avatar"></el-image>
                <el-image v-if="friend.status === 'accepted'" :src="friend.currentStatus === 'online' ? onlineIcon : offlineIcon" class="user-status"></el-image>
            </el-col>
            <el-col :xs="{span: 16}" :sm="{span: 16}">
                <el-row type="flex" justify="left" class="friend-name">
                    {{(friend.username || 'Anonymous')}}
                </el-row>
                <el-row type="flex" justify="left">
                    <span class="friend-email">{{friend.email}}</span>
                </el-row>
                <el-row type="flex" justify="left" class="friend-status">
                    <span class="friend-status-none" v-if="friend.status === 'none'">Status: -</span>
                    <span class="friend-status-waiting hidden-sm-and-down" v-if="friend.status === 'waiting'" >Status: Request sended</span>
                    <span class="friend-status-waiting hidden-md-and-up" v-if="friend.status === 'waiting'" >Status: Waiting...</span>
                    <span class="friend-status-pending hidden-sm-and-down" v-if="friend.status === 'pending'">Status: Received request</span>
                    <span class="friend-status-pending hidden-md-and-up" v-if="friend.status === 'pending'">Status: Request...</span>
                    <span class="friend-status-declined" v-if="friend.status === 'decline'">Status: Declined</span>
                    <span class="friend-status-accepted" v-if="friend.status === 'accepted'">Status: friends</span>
                </el-row>

                <!---------------------------->
                <!-- Show invite button if user isn't friend -->
                <!---------------------------->
                <el-row type="flex" justify="left" v-if="friend.status === 'none'">
                    <span class="friend-request clickable friend-status-accepted" @click="sendFriendRequest"><i class="el-icon-plus"></i>Add friend</span>
                </el-row>

                <!---------------------------->
                <!-- Show cancel button if waiting for response -->
                <!---------------------------->
                <el-row type="flex" justify="left" v-if="friend.status === 'waiting'">
                    <span class="friend-request clickable friend-status-declined" @click="submitFriendRequest('decline')"><i class="el-icon-close"></i>Cancel</span>
                </el-row>

                <!---------------------------->
                <!-- Show accept or decline buttons if receiving request -->
                <!---------------------------->
                <el-row type="flex" justify="left" v-if="friend.status === 'pending'">
                    <span class="friend-request clickable friend-status-accepted" @click="submitFriendRequest('accept')"><i class="el-icon-check"></i>Accept</span>
                    <span class="friend-request clickable friend-status-declined" style="margin-left: 6px;" @click="submitFriendRequest('decline')"><i class="el-icon-close"></i>Decline</span>
                </el-row>

                <!---------------------------->
                <!-- Show self -->
                <!---------------------------->
                <el-row type="flex" justify="left" v-if="friend.status === 'self'" class="friend-status">
                    self
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
// @ is an alias to /src
import ErrorHandling from '@/utils/error-handling.js'

export default {
  name: 'FriendsTile',
  components: {},
  props: ['friend', 'user', 'addFriend', 'removeFriend', 'onEmit'],
  data () {
    return {
      onlineIcon: require(`@/assets/icon-onn.png`),
      offlineIcon: require(`@/assets/icon-off.png`),
      awayIcon: require(`@/assets/icon-away.png`),

      submitting: false,
      avatar: require(`@/assets/${this.friend.character || 'char-1'}-icon.png`)
    }
  },

  methods: {
    // ---------------------------------------
    // Endpoints 1/2 (Happy flow)
    // ---------------------------------------
    async sendFriendRequest () {
      if (this.submitting) {
        this.$message.error('Already submitting, please wait!')
        return
      }

      this.submitting = true

      const res = await this.$http.post(`friendships`, { user: this.friend._id })
      if (res.errors && res.errors.length) {
        ErrorHandling(res.errors, 'submitFriendship', { handleMessage: this.$message.error })
        this.submitting = false
      } else {
        this.submitting = false
        this.$message.success('Friend request send!')
        if (this.onEmit) this.onEmit('newFriendRequest')
        this.friend.status = 'waiting'
        this.addFriend(this.friend, 'none')
      }
    },

    async submitFriendRequest (status) {
      if (this.submitting) {
        this.$message.error('Already submitting, please wait!')
        return
      }

      this.submitting = true

      const res = await this.$http.put(`friendships/${this.friend._id}/submit/${status}`)
      if (res.errors && res.errors.length) {
        ErrorHandling(res.errors, 'submitFriendship', { handleMessage: this.$message.error })
        this.submitting = false
      }

      else {
        this.submitting = false
        if (status === 'decline') {
          if (this.onEmit) {
            if (this.friend.status === 'accepted') this.onEmit('declinedFriendship')
            if (this.friend.status === 'waiting') this.onEmit('declinedOutgoingRequest')
            if (this.friend.status === 'pending') this.onEmit('declinedReceivedRequest')
          }
          this.friend.status = 'decline'
          this.removeFriend(this.friend)
          this.$message.success('Friend request declined!')
        } else if (status === 'accept') {
          if (this.onEmit) if (this.friend.status === 'pending') this.onEmit('acceptReceivedRequest')
          this.friend.status = 'accepted'
          this.addFriend(this.friend)
          this.$message.success('Friend request accepted!')
        }
      }
    },
  }
}
</script>

<style lang="scss">
    @import '../styles/variables.scss';

    .friends-tile {
        max-width: 80%;
        margin: 10px;

        .user-avatar {
            margin-top: -30px;
            margin-left: -20px;
            width: 110px;
            height: 110px;
        }

        .user-status {
            position: absolute;
            width: 25px;
            height: 25px;
            margin-left: -18px;
            margin-top: 52px;
        }

        .friend-name {
            margin-bottom: 2px;
            text-shadow: #d6d0b5 1px 0 10px;
            font-size: $font-size-small;
        }
        .friend-email {
            margin-bottom: 2px;
            font-size: $font-size-small;
            text-align: left;
            vertical-align: middle;
            padding-right: 5px;
            overflow: hidden;
        }
        .friend-status {
            font-size: $font-size-small;
            margin-bottom: 2px;
        }
        .friend-request {
            font-size: $font-size-small;
        }
        .friend-status-waiting {
            text-shadow: #0001ff 1px 0 10px;
        }
        .friend-status-pending {
            text-shadow: #0001ff 1px 0 10px;
        }
        .friend-status-declined {
            text-shadow: #ff0000 1px 0 10px;
        }
        .friend-status-accepted {
            text-shadow: #00ff4f 1px 0 10px;
        }

        @media screen and (max-width: 61em) {
            max-width: 80%;
            margin: 5px;

            .friend-name {
                margin-left: 5px;
                font-size: $font-size-small;
            }

            .friend-status {
                margin-left: 5px;
                font-size: $font-size-small;
            }
            .friend-email {
                margin-left: 5px;
                margin-bottom: 2px;
                font-size: $font-size-small;
                text-align: left;
                vertical-align: middle;
                padding-right: 5px;
                overflow: hidden;
            }
            .user-status {
                margin-top: 50px !important;
            }
        }
    }
</style>
