<template>
  <div class="socket-events">
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'SocketEvents',
  components: {
  },
  props: {
  },
  data () {
    return {}
  },

  mounted () {
    // Add sockets events
    this.socketEvents()
  },

  methods: {
    socketEvents () {
      const self = this
      const h = this.$createElement

      // ---------------------------------------
      // Notification when receiving new friend request
      // ---------------------------------------
      this.$socket.on('newFriendRequest', function (data) {
        self.$notify({
          title: 'Received friend request:',
          message: h('div', { class: { 'notifier-wrapper': true } }, [
            h('img', {
              class: { 'notifier-img': true },
              attrs: {
                src: require(`@/assets/mini-${data.fromUser.avatar}.png`) // this is now a module request
              }
            }),
            h('p', { class: { 'notifier-name': true } }, data.fromUser.username),
            h('p', { class: { 'notifier-email': true } }, data.fromUser.email)
          ]),
          duration: 5000
        })
      })

      // ---------------------------------------
      // Notification when Friendship is accepted
      // ---------------------------------------
      this.$socket.on('acceptFriendRequest', function (data) {
        // self.$notify.success({
        self.$notify({
          title: 'Friendship accepted:',
          message: h('div', { class: { 'notifier-wrapper': true } }, [
            h('img', {
              class: { 'notifier-img': true },
              attrs: {
                src: require(`@/assets/mini-${data.fromUser.avatar}.png`) // this is now a module request
              }
            }),
            h('p', { class: { 'notifier-name': true } }, data.fromUser.username),
            h('p', { class: { 'notifier-email': true } }, data.fromUser.email)
          ]),
          duration: 5000
        })
      })

      // ---------------------------------------
      // Notification when Friendship is declined
      // ---------------------------------------
      this.$socket.on('denyFriendRequest', function (data) {
        // self.$notify.error({
        self.$notify({
          title: 'Friendship declined:',
          message: h('div', { class: { 'notifier-wrapper': true } }, [
            h('img', {
              class: { 'notifier-img': true },
              attrs: {
                src: require(`@/assets/mini-${data.fromUser.avatar}.png`) // this is now a module request
              }
            }),
            h('p', { class: { 'notifier-name': true } }, data.fromUser.username),
            h('p', { class: { 'notifier-email': true } }, data.fromUser.email)
          ]),
          duration: 5000
        })
      })
    }
  }
}
</script>
<style lang="scss">
  @import '../styles/variables.scss';
  .socket-events {

  }
</style>
