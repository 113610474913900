import Vue from 'vue'
import VueRouter from 'vue-router'
import Error from '../views/Error.vue'
import Home from '../views/Home.vue'
import Friends from '../views/Friends.vue'
import Lobby from '../views/Lobby.vue'

import Character from '../views/Character.vue'
import Account from '../views/Account.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/error',
    name: 'error',
    component: Error
  },
  {
    path: '/friends',
    name: 'friends',
    component: Friends,
    meta: { requiresAuth: true }
  },
  {
    path: '/character',
    name: 'character',
    component: Character,
    meta: { requiresAuth: true }
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: { requiresAuth: true }
  },
  {
    path: '/lobby',
    name: 'lobby',
    component: Lobby,
    meta: { requiresAuth: true }
  },

  // All stuff for terms etc.
  {
    path: '/about',
    component: () => import(/* webpackChunkName: "Terms" */ '../views/Terms.vue'),
    children: [
      { path: '', name: 'about', component: () => import(/* webpackChunkName: "About" */ '../components/terms/about.vue') }
    ]
  },
  {
    path: '/cookies-policy',
    component: () => import(/* webpackChunkName: "Terms" */ '../views/Terms.vue'),
    children: [
      { path: '', name: 'cookiesPolicy', component: () => import(/* webpackChunkName: "CookiesPolicy" */ '../components/terms/cookies-policy.vue') }
    ]
  },
  {
    path: '/terms-of-use',
    component: () => import(/* webpackChunkName: "Terms" */ '../views/Terms.vue'),
    children: [
      { path: '', name: 'termsOfUse', component: () => import(/* webpackChunkName: "TermsOfUse" */ '../components/terms/terms-of-use.vue') }
    ]
  },
  {
    path: '/terms-of-service',
    component: () => import(/* webpackChunkName: "Terms" */ '../views/Terms.vue'),
    children: [
      { path: '', name: 'termsOfService', component: () => import(/* webpackChunkName: "TermsOfService" */ '../components/terms/terms-of-service.vue') }
    ]
  },
  {
    path: '/terms-and-conditions',
    component: () => import(/* webpackChunkName: "Terms" */ '../views/Terms.vue'),
    children: [
      { path: '', name: 'termsAndConditions', component: () => import(/* webpackChunkName: "TermsAndConditions" */ '../components/terms/terms-and-conditions.vue') }
    ]
  },
  {
    path: '/disclaimer',
    component: () => import(/* webpackChunkName: "Terms" */ '../views/Terms.vue'),
    children: [
      { path: '', name: 'disclaimer', component: () => import(/* webpackChunkName: "Disclaimer" */ '../components/terms/disclaimer.vue') }
    ]
  },
  {
    path: '/eula',
    component: () => import(/* webpackChunkName: "Terms" */ '../views/Terms.vue'),
    children: [
      { path: '', name: 'eula', component: () => import(/* webpackChunkName: "Eula" */ '../components/terms/eula.vue') }
    ]
  },
  {
    path: '/privacy-policy',
    component: () => import(/* webpackChunkName: "Terms" */ '../views/Terms.vue'),
    children: [
      { path: '', name: 'privacyPolicy', component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../components/terms/privacy-policy.vue') }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
