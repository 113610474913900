import 'element-ui/lib/theme-chalk/display.css';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import io from 'socket.io-client'

import './styles/index.scss'

Vue.use(ElementUI)

Vue.config.productionTip = false

Vue.prototype.$http = axios
Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_URL
Vue.prototype.$http.defaults.headers.common.Accept = 'application/json'
Vue.prototype.$http.defaults.withCredentials = true
Vue.prototype.$http.defaults.timeout = 30000

// -----------------------------
// Tmp: Socket.io
// -----------------------------
Vue.prototype.$socket = io.connect(process.env.VUE_APP_API_URL.replace('/api/v1', ''));

Vue.prototype.$http.interceptors.response.use(
function(response) {
  return response.data
  },
function (error) {
  // handle error
  if (error.response && error.response.data) {
    return error.response.data
  } else {
    console.error(error)
  }
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  // Check authorization for user with session
  if (store.state.user) {
    return next()
  }

  router.app.$http.get('users/self').then((res) => {
    if (res.list && res.list[0]) {
      store.commit('user', res.list[0])

      Vue.prototype.$socket.emit('claim', { token: res.list[0].socketToken });
    }
    if (requiresAuth && res.list && res.list[0]) next()
    else if (requiresAuth) next({ name: 'home', query: { mode: 'login' } })
    else next()
  })
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
