module.exports = function chunkArray (arr, chunkSize = 2) {
  let index = 0
  let arrayLength = arr.length
  let result = []

  for (index = 0; index < arrayLength; index += chunkSize) {
    result.push(arr.slice(index, index + chunkSize))
  }

  return result
}
