<template>
  <div class="page-content">
    <top-menu :setUser="setUser" :user="user" :changeMode="changeMode" :key="user ? `top-menu-${user._id}` : 'top-menu'"></top-menu>

    <el-row type="flex" justify="center" class="page-body home-page">
      <el-col :xs="{span: 24}" :sm="{span: 24}" class="content-wrapper home-row">
        <background v-if="!user" :desktop="'left-party'" :overflow="true"></background>
        <background v-if="!user" :desktop="'right-party'"></background>

        <background v-if="user" :desktop="'env1'"></background>

        <background></background>

<!--        <profile-menu-mobile v-if="user" :user="user" :changeMode="changeMode" :mode="mode"></profile-menu-mobile>-->

        <!---------------------------->
        <!-- If user, show profile -->
        <!---------------------------->
        <el-row type="flex" justify="left" v-if="user">
          <character-form :user="user" :setUser="setUser"></character-form>
        </el-row>

        <!---------------------------->
        <!-- If no user, try to authorize client -->
        <!---------------------------->
        <login-card v-if="!user" :onLogin="setUser"></login-card>
      </el-col>
    </el-row>

    <page-footer :key="'footer'"></page-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import TopMenu from '@/components/top-menu.vue'
import PageFooter from '@/components/page-footer.vue'

import Background from '@/components/background.vue'

import LoginCard from '@/components/login-card.vue'

import CharacterForm from '@/components/character-form.vue'

export default {
  name: 'Home',
  components: {
    TopMenu,
    PageFooter,

    Background,

    LoginCard,

    CharacterForm,
  },
  mounted () {
    // Add socket events
    this.socketEvents()

    // Set mode
    this.setMode()

    // If user, update user counts when mounted
    if (this.user) this.updateCounts()
  },
  data () {
    return {
      user: this.$store.state.user,
      mode: 'profile'
    }
  },
  methods: {
    socketEvents () {
      const self = this
      // ---------------------------------------
      // Socket events (friendships to update counts)
      // ---------------------------------------
      this.$socket.on('newFriendRequest', function (data) {
        self.user.counts.receivedRequests += 1
        if (self.user.counts.receivedRequests < 0) self.user.counts.receivedRequests = 0
      })

      this.$socket.on('acceptFriendRequest', function (data) {
        self.user.counts.outgoingRequests += 1
        self.user.counts.friends += 1
      })

      this.$socket.on('denyFriendRequest', function (data) {
        if (data.fromStatus === 'pending') {
          if (data.requester === self.user._id) {
            self.user.counts.outgoingRequests -= 1
            if (self.user.counts.outgoingRequests < 0) self.user.counts.outgoingRequests = 0
          }
          if (data.requester !== self.user._id) {
            self.user.counts.receivedRequests -= 1
            if (self.user.counts.receivedRequests < 0) self.user.counts.receivedRequests = 0
          }
        }

        if (data.fromStatus === 'accepted') {
          self.user.counts.friends -= 1
          if (self.user.counts.friends < 0) self.user.counts.friends = 0
        }
      })
    },

    setMode () {
      // If user, and user tries to enter mode for no-user only, goto profile
      if (this.user && ['login', 'register', 'recover', 'recovery', 'verify'].includes(this.$route.query.mode)) {
        this.changeMode('profile')
      }

      // If no user, go to login page
      if (!this.user) {
        this.changeMode('login')
        return
      }

      // If user and no valid mode found, goto profile
      this.$router.push({ name: 'lobby' })
    },

    changeMode (mode) {
      this.mode = mode
      this.$router.replace({ query: { mode: mode } })
    },

    onEmit (event) {
      // Events to listen to for update counts
      if (event === 'declinedFriendship') {
        this.user.counts.friends -= 1
        if (this.user.counts.friends < 0) this.user.counts.friends = 0
      }
      if (event === 'declinedOutgoingRequest') {
        this.user.counts.outgoingRequests -= 1
        if (this.user.counts.outgoingRequests < 0) this.user.counts.outgoingRequests = 0
      }
      if (event === 'declinedReceivedRequest') {
        this.user.counts.receivedRequests -= 1
        if (this.user.counts.receivedRequests < 0) this.user.counts.receivedRequests = 0
      }
      if (event === 'acceptReceivedRequest') {
        this.user.counts.receivedRequests -= 1
        this.user.counts.friends += 1
        if (this.user.counts.receivedRequests < 0) this.user.counts.receivedRequests = 0
      }
      if (event === 'newFriendRequest') {
        this.user.counts.outgoingRequests += 1
      }
    },

    setUser (user, mode) {
      if (user) {
        this.$store.commit('user', user)
        this.user = user
        if (user.socketToken) this.$socket.emit('claim', { token: user.socketToken })
        this.userAvatar = require(`@/assets/${user.avatar || 'avatar1'}.png`)
        if (mode) this.changeMode(mode)
        else this.$router.push({ name: 'lobby' })

      } else {
        this.$store.commit('user', false)
        this.user = false
        this.mode = 'login'
      }
    },

    async updateCounts () {
      const res = await this.$http.get('counts/self')
      if (res.errors && res.errors.length) {
        for (let error of res.errors) console.error(error)
      } else {
        this.user.counts = res.list[0]
      }
    }
  }
}
</script>
<style lang="scss">
  @import '../styles/variables.scss';

  .home-page {
    min-height: 700px;
    .el-divider__text {
      color: $main-color-2;
      background-color: transparent;
      font-size: $font-size-large;
    }

    .el-divider {
      background-color: transparent;
    }

    .el-form-item__label {
      color: #c6bfbf;
    }
  }
</style>
