<template>
    <div class="account-form-form">
        <el-form ref="form" :model="form" label-width="200px">
            <!---------------------------->
            <!-- User username -->
            <!---------------------------->
            <el-form-item class="less-spacing" label="Username">
                <el-input v-model="form.username" class="retro" style="width: 100%"></el-input>
            </el-form-item>

            <!---------------------------->
            <!-- User Email -->
            <!---------------------------->
            <el-form-item class="less-spacing" label="Email">
                <el-input v-model="form.email" disabled class="retro"></el-input>
            </el-form-item>

            <!---------------------------->
            <!-- User password -->
            <!---------------------------->
            <el-form-item label="Password" style="text-align: left;">
                <el-col :xs="{span: 24}" :sm="{span: 21}" class="hidden-sm-and-up" >
                    <div style="margin: 1px;"></div>
                </el-col>

                <el-button type="primary" icon="el-icon-key" plain class="retro" v-if="!changePasswordMode" @click="changePasswordMode = true">Change password</el-button>
                <el-input v-model="form.password" placeholder="Current password" show-password v-if="changePasswordMode" :class="'retro retro-prepend ' + validate.password">
                    <template slot="prepend">
                        <i class="el-icon-unlock"></i>
                    </template>
                </el-input>
            </el-form-item>

            <el-form-item v-if="changePasswordMode">
                <el-input v-model="form.newPassword" placeholder="New password" show-password :class="'retro retro-prepend ' + validate.newPassword">
                    <template slot="prepend">
                        <i class="el-icon-key"></i>
                    </template>
                </el-input>
            </el-form-item>

            <el-form-item v-if="changePasswordMode">
                <el-input v-model="form.confirmPassword" placeholder="Confirm new password"  show-password :class="'retro retro-prepend ' + validate.confirmPassword">
                    <template slot="prepend">
                        <i class="el-icon-refresh"></i>
                    </template>
                </el-input>
            </el-form-item>

            <!---------------------------->
            <!-- Action buttons -->
            <!---------------------------->
            <el-button type="danger" icon="el-icon-refresh-left" plain class="retro action-button" @click="reset">Reset form</el-button>
            <el-button type="success" icon="el-icon-finished" plain class="retro action-button" @click="update" :loading="submitting">Save changes</el-button>
        </el-form>
    </div>
</template>

<script>
// @ is an alias to /src
import chunkArray from '@/utils/chunk-array.js'
import ErrorHandling from '@/utils/error-handling.js'

export default {
  name: 'AccountForm',
  components: {},
  props: ['user', 'setUser'],
  data () {
    return {
      submitting: false,
      form: {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        username: this.user.username,
        email: this.user.email,
        avatar: this.user.avatar || 'avatar1',
        password: '',
        newPassword: '',
        confirmPassword: ''
      },
      validate: {
        password: 'none',
        newPassword: 'none',
        confirmPassword: 'none'
      },
      changePasswordMode: false
    }
  },

  methods: {
    chunkArray,

    setAvatar (avatar) {
      this.avatars = this.avatars.map(el => {
        el.selected = el.name === avatar.name
        return el
      })

      this.avatar = avatar.img
      this.userAvatar = avatar.img
      this.form.avatar = avatar.name
    },

    reset () {
      this.form.firstName = this.user.firstName
      this.form.lastName = this.user.lastName
      this.form.username = this.user.username
      this.form.email = this.user.email
      this.form.avatar = this.user.avatar || 'avatar1'

      this.form.password = ''
      this.form.newPassword = ''
      this.form.confirmPassword = ''

      this.validate.password = 'none'
      this.validate.newPassword = 'none'
      this.validate.confirmPassword = 'none'

      this.changePasswordMode = false

      for (let avatar of this.avatars) {
        const isSelected = (avatar.name === this.user.avatar)
        avatar.selected = isSelected
        if (isSelected) {
          this.avatar = avatar.img
          this.userAvatar = avatar.img
        }
      }
    },

    handleValidation (obj) {
      const keys = Object.keys(obj)
      for (let key of keys) {
        this.validate[key] = obj[key]
      }
    },

    validatePassword () {
      if (!this.form.password) {
        this.validate.password = 'invalid'
        this.$message.error('Password required!')
        return false
      }

      if (this.form.password.length < 6) {
        this.validate.password = 'invalid'
        this.$message.error('Minimum Password length is 6 characters!')
        return false
      }

      if (this.form.password.length > 30) {
        this.validate.password = 'invalid'
        this.$message.error('Maximum Password length is 30 characters!')
        return false
      }

      this.validate.password = 'valid'
      return true
    },

    validateNewPassword () {
      if (!this.form.newPassword) {
        this.validate.newPassword = 'invalid'
        this.$message.error('New password required!')
        return false
      }

      if (this.form.newPassword.length < 6) {
        this.validate.newPassword = 'invalid'
        this.$message.error('Minimum New password length is 6 characters!')
        return false
      }

      if (this.form.newPassword.length > 30) {
        this.validate.newPassword = 'invalid'
        this.$message.error('Maximum New password length is 30 characters!')
        return false
      }

      this.validate.newPassword = 'valid'
      return true
    },

    validateConfirmPassword () {
      if (this.form.newPassword !== this.form.confirmPassword) {
        this.validate.password = 'none'
        this.validate.newPassword = 'none'
        this.validate.confirmPassword = 'invalid'
        this.$message.error('Confirmation password does not equal password!')
        return false
      }

      this.validate.confirmPassword = 'valid'
      return true
    },

    // ---------------------------------------
    // Endpoints 1/2 (Happy flow)
    // ---------------------------------------
    async update () {
      if (this.submitting) {
        this.$message.error('Already submitting, please wait!')
        return
      }

      if (this.changePasswordMode) {
        const isValidPassword = this.validatePassword()
        const isValidNewPassword = isValidPassword && this.validateNewPassword()
        const isValid = isValidPassword && isValidNewPassword && this.validateConfirmPassword()
        if (!isValid) return
      }

      this.submitting = true

      const res = await this.$http.put(`users/${this.user._id}`, this.form)

      if (res.errors && res.errors.length) {
        ErrorHandling(res.errors, 'updateUserProfile', { handleMessage: this.$message.error, handleValidation: this.handleValidation, handleMode: this.changeMode })
        this.submitting = false
      } else {
        this.submitting = false
        if (!this.changePasswordMode) {
          this.$message.success('Settings changed successfully!')
          this.setUser(res.list[0])
        }
        if (this.changePasswordMode) {
          this.$message.success('Settings changed successfully, please login again with your new password!')
          this.setUser(false)
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '../styles/variables.scss';

.account-form-form {
    width: 80%;
}
</style>
