<template>
  <div class="top-menu">
    <el-row type="flex" justify="left" class="inner-top-menu">
      <div></div>
    </el-row>

    <el-row type="flex" justify="center">
      <socket-events></socket-events>

      <el-col :xs="{span: 24}" :sm="{span: 24}" class="content-wrapper">
        <el-row type="flex" justify="left">
          <el-col :xs="{span: 8}" :sm="{span: 8}" class="home">
            <el-row type="flex" justify="left" class="pages hidden-sm-and-down">
              <span style="margin-left: 5px; margin-right: 5px;" v-if="user"> - </span>
              <span @click="to('lobby')" class="clickable menu-button" v-if="user">
                Lobby
              </span>

              <span style="margin-left: 5px; margin-right: 5px;" v-if="user"> - </span>
              <span @click="to('character')" class="clickable menu-button" v-if="user">
                Character
              </span>

              <span style="margin-left: 5px; margin-right: 5px;" v-if="user"> - </span>
              <span @click="to('friends')" class="clickable menu-button" v-if="user">
                Friends
              </span>

              <span style="margin-left: 5px; margin-right: 5px;" v-if="user"> - </span>
              <span @click="to('account')" class="clickable menu-button" v-if="user">
                Account
              </span>
            </el-row>
          </el-col>

          <el-col :xs="{span: 8}" :sm="{span: 8}" class="home">
            <el-row type="flex" justify="center">
              <div class="logo-background">
                <a href="/" class="clean-href">
                  <el-image :src="logoBackgroundImg" :class="'home-logo-background'"></el-image>
                </a>
              </div>
              <div class="logo">
                <a href="/" class="clean-href">
                  <el-image :src="logoImg" :class="'home-logo clickable'"></el-image>
                </a>
              </div>
            </el-row>
          </el-col>

          <el-col :xs="{span: 8}" :sm="{span: 8}">
            <el-row type="flex" justify="end" class="pages hidden-sm-and-down">
              <span style="margin-left: 5px; margin-right: 5px;" v-if="user"> - </span>
              <span @click="to('about')" class="clickable menu-button">
                About
              </span>
              <span style="margin-left: 5px; margin-right: 5px;" v-if="user"> - </span>
              <span @click="logout" class="clickable menu-button" v-if="user">
                Logout
              </span>
            </el-row>

            <el-row type="flex" justify="end" class="pages hidden-md-and-up">
              <el-button @click="expandedMobileMenu = !expandedMobileMenu" type="text" size="medium" :icon="!expandedMobileMenu ? 'el-icon-caret-bottom' : 'el-icon-circle-close'">Menu</el-button>
            </el-row>
          </el-col>
        </el-row>

        <div class="expanded-mobile-menu hidden-md-and-up" v-if="expandedMobileMenu">
          <el-row type="flex" justify="center">
            <el-col :xs="{span: 24}" :sm="{span: 24}">
              <el-row>
                <div @click="to('about')" class="clickable">
                  About
                </div>
              </el-row>
              <el-row>
                <div @click="logout" class="clickable" v-if="user">
                  Logout
                </div>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// @ is an alias to /src
import ErrorHandling from '@/utils/error-handling.js'

import SocketEvents from '@/components/socket-events'

export default {
  name: 'TopMenu',
  components: {
    SocketEvents
  },
  props: {
    changeMode: {
      default: false
    },
    setUser: {
      default: () => {}
    },
    user: {
      default: () => {}
    }
  },
  data () {
    return {
      submitting: false,
      logoImg: require(`@/assets/logo.png`),
      logoBackgroundImg: require(`@/assets/logo-background.png`),
      expandedMobileMenu: false
    }
  },

  mounted () {
  },

  methods: {
    toMode (name, mode) {
      if (this.changeMode) this.changeMode(mode)
      else this.to(name)
    },
    to (name) {
      this.$router.push({ name: name })
    },
    // ---------------------------------------
    // Endpoints 1/2 (Happy flow)
    // ---------------------------------------
    async logout () {
      if (this.submitting) {
        this.$message.error('Already submitting, please wait!')
        return
      }

      const res = await this.$http.post('users/logout')
      if (res.errors && res.errors.length) {
        ErrorHandling(res.errors, 'userLogout', { handleMessage: this.$message.error })
        this.submitting = false
      } else {
        this.submitting = false
        if (this.setUser) {
          this.setUser(false)
        } else {
          this.$store.commit('user', false)
          this.$router.go('home')
        }
      }
    }
  }
}
</script>
<style lang="scss">
  @import '../styles/variables.scss';
  .top-menu {
    width: 100%;
    padding: 1em;
    position: fixed;
    background-color: #141413;
    z-index:1000;
    font-size: $font-size-regular;

    border-bottom-width: 1px;
    border-bottom-color: $main-color-2;
    border-bottom-style: solid;

    .inner-top-menu {
      margin-top: -1em;
      margin-left: -1em;
      background-color: #3f3c38;
      height: 40px;
    }

    .menu-button {
      color: #d6dddd;
    }

    .home {
      padding: 0em 3em 0em 0em;
      font-size: $font-size-regular;
      font-weight: 600;
    }

    height: 80px;

    .pages {
      margin-top: 40px;
      padding: 0em 3em 0em 0em;
      font-size: $font-size-regular;
    }

    .home-logo-background {
      position: absolute;
      width: 314px;
      height: 206px;
      margin-top: -120px;
      margin-left: -157px;
    }


    .home-logo {
      position: absolute;
      width: 170px;
      height: 90px;
      margin-top: -30px;
      margin-left: -85px;
    }

    .user-avatar {
      margin-top: 40px;

      .user-avatar-name {
        margin-top: 5px;
        margin-left: 5px;
        float: left;
      }
    }

    .top-sub-menu {
      margin-top: 10px;
    }

    .expanded-mobile-menu {
      padding-top: 20px;
      padding-bottom: 20px;
      margin-left: -1em;

      top: 0px;
      color: $main-color-2;
      background-color: $main-color-1;
      border-bottom-color: $main-color-2;
      border-bottom-style: solid;
      border-bottom-width: 1px;
    }

    .el-button {
      color: $main-color-1 !important;
    }
  }

  @media screen and (max-width: 61em) {
    .top-menu {
      height: 25px;

      .inner-top-menu {
        height: 25px;
      }

      .home-logo-background {
        position: absolute;
        width: 209px;
        height: 137px;
        margin-top: -70px;
        margin-left: -105px;
      }

      .home-logo {
        position: absolute;
        width: 125px;
        height: 69px;
        margin-top: -22px;
        margin-left: -60px;
      }

      .pages {
        margin-top: -0.1em;
        margin-left: 2em;
      }

      .user-avatar {
        margin-top: 0em;
      }
    }
  }
</style>
