<template>
  <div class="page-content">
    <top-menu :user="user" :key="user ? `top-menu-${user._id}` : 'top-menu'"></top-menu>

    <el-row type="flex" justify="center" class="page-body account-page">
      <el-col :xs="{span: 24}" :sm="{span: 24}" class="content-wrapper home-row">

        <background></background>

        <!---------------------------->
        <!-- If user, show profile -->
        <!---------------------------->
        <el-row type="flex" justify="left" v-if="user">
          <account-form :user="user" :setUser="setUser"></account-form>
        </el-row>

      </el-col>
    </el-row>

    <page-footer :key="'footer'"></page-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import TopMenu from '@/components/top-menu.vue'
import PageFooter from '@/components/page-footer.vue'

import Background from '@/components/background.vue'

import LoginCard from '@/components/login-card.vue'

import AccountForm from '@/components/account-form.vue'

export default {
  name: 'Account',
  components: {
    TopMenu,
    PageFooter,

    Background,

    LoginCard,

    AccountForm,
  },
  mounted () {
  },
  data () {
    return {
      user: this.$store.state.user,
    }
  },
  methods: {
    setUser (user, mode) {
      if (user) {
        this.$store.commit('user', user)
        this.user = user
        if (user.socketToken) this.$socket.emit('claim', { token: user.socketToken })
      }
    },
  }
}
</script>
<style lang="scss">
  @import '../styles/variables.scss';

  .account-page {
    min-height: 700px;
    .el-divider__text {
      color: $main-color-2;
      background-color: transparent;
      font-size: $font-size-large;
    }
  }
</style>
