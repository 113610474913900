<template>
    <div class="character-form-form">
        <!---------------------------->
        <!-- Character -->
        <!---------------------------->
        <el-col :xs="{span: 1}" :sm="{span: 1}">
            <div style="margin: 1px;"></div>
        </el-col>
        <el-col :xs="{span: 13}" :sm="{span: 13}">
            <el-row v-for="(row, index) of chunkArray(characters, 4)" v-bind:key="`character-outer-row-${index}`" type="flex" justify="left" style="margin-left:10px;">
                <span v-for="(el, indexOuter) of row" v-bind:key="`character-inner-row-${index}-${indexOuter}`">
                    <el-image
                        @click="setCharacter(el)"
                        v-show="el.selected ? el.imgIconSelectedLoaded : el.imgIconLoaded"
                        @load="onImgLoad(el.selected ? 'iconSelected' : 'icon', el.name)"
                        :src="el.selected ? el.imgIconSelected : el.imgIcon"
                        :class="`character-mini-preview  ${el.selected ? 'selected' : ''}`"
                    ></el-image>
                    <div :class="`character-mini-preview`" v-show="el.selected ? !el.imgIconSelectedLoaded : !el.imgIconLoaded"></div>
                </span>

            </el-row>
        </el-col>

        <el-col :xs="{span: 10}" :sm="{span: 10}">
            <el-image :src="character.imgAvatar"
                      v-show="character.imgAvatarLoaded"
                      @load="onImgLoad('avatar', character.name)"
                      :class="`character-preview`"
            ></el-image>

            <el-image :src="character.imgClassIcon"
                  v-show="character.imgClassIconLoaded"
                  @load="onImgLoad('classIcon', character.name)"
                  :class="`class-icon`"
            ></el-image>
            <div :class="`character-preview`" v-show="!character.imgAvatarLoaded"></div>
            <el-button type="primary" :loading="submitting" plain class="retro action-button" :disabled="form.character === lastCharacter.name" @click="update">Save character</el-button>
        </el-col>
    </div>
</template>

<script>
// @ is an alias to /src
import chunkArray from '@/utils/chunk-array.js'
import ErrorHandling from '@/utils/error-handling.js'

export default {
  name: 'CharacterForm',
  components: {},
  props: ['user', 'setUser'],
  data () {
    let characters = []
    let character = false

    for (let i = 1; i < 5; i++) {
      const name = `char-${i}`
      const className = [1, 2].includes(i) ? 'alch' : [3, 4].includes(i) ? 'clerg' : 'alch'
      const characterInfo = {
        name: name,
        className: className,
        imgAvatar: require(`@/assets/char-${i}-avatar.png`),
        imgClassIcon: require(`@/assets/icon-${className}-1.png`),
        imgIcon: require(`@/assets/char-${i}-icon.png`),
        imgIconSelected: require(`@/assets/char-${i}-icon-selected.png`),
        selected: name === (this.user.character || 'char-1'),
        imgAvatarLoaded: false,
        imgIconLoaded: false,
        imgIconSelectedLoaded: false,
        imgClassIconLoaded: false
      }
      if (name === (this.user.character || 'char-1')) {
        character = characterInfo
      }

      characters.push(characterInfo)
    }

    return {
      submitting: false,
      form: {
        character: this.user.character || 'char-1',
      },
      characters: characters,
      character: character,
      lastCharacter: character,
      userCharacter: character
    }
  },

  methods: {
    chunkArray,

    onImgLoad (type, name) {
      let character = this.characters.find(el => el.name === name)

      if (!character) return
      if (type === 'avatar') character.imgAvatarLoaded = true
      if (type === 'icon') character.imgIconLoaded = true
      if (type === 'avatarIcon') character.imgAvatarIconLoaded = true
      if (type === 'classIcon') character.imgClassIconLoaded = true

      for (let el of this.characters) {
        if (el.name === name) {
          if (type === 'avatar') el.imgAvatarLoaded = true
          if (type === 'icon') el.imgIconLoaded = true
          if (type === 'iconSelected') el.imgIconSelectedLoaded = true
        }
        if (el.className === character.className) {
          if (type === 'classIcon') el.imgClassIconLoaded = true
        }
      }
    },

    setCharacter (character) {
      let currentCharacterName = this.character.name
      this.lastCharacter = this.character

      for (let el of this.characters) {
        el.selected = el.name === character.name
        if (el.name !== currentCharacterName) {
          el.imgAvatarLoaded = false
          el.imgIconSelectedLoaded = false
          if (character.className !== el.className) el.imgClassIconLoaded = false
        }
        else {
          el.imgIconLoaded = false
        }
      }

      this.character = character
      this.userCharacter = character.imgAvatar
      this.form.character = character.name
    },

    // ---------------------------------------
    // Endpoints 1/2 (Happy flow)
    // ---------------------------------------
    async update () {
      if (this.submitting) {
        this.$message.error('Already submitting, please wait!')
        return
      }

      this.submitting = true

      const res = await this.$http.put(`users/${this.user._id}`, this.form)

      if (res.errors && res.errors.length) {
        ErrorHandling(res.errors, 'updateUserProfile', { handleMessage: this.$message.error, handleValidation: this.handleValidation, handleMode: this.changeMode })
        this.submitting = false
      } else {
        this.submitting = false
        this.$message.success('Character saved!')
      }
    }
  }
}
</script>

<style lang="scss">
@import '../styles/variables.scss';

.character-form-form {
    .character-mini-preview.selected {
        transform: scale(1.05);
    }

    .character-mini-preview {
        width: 135px;
        height: 135px;
        margin: 5px;
        cursor: pointer;
    }

    .character-mini-preview:hover {
        transform: scale(1.1);
        transition-duration: 0.2s;
        cursor: pointer;
    }

    .character-preview {
        width: 521px;
        height: 737px;
    }

    .class-icon {
        position: absolute;
        margin-top: -50em;
        margin-left: -20em;
        width: 445px;
        height: 591px;
        z-index: -2;
    }

    @media screen and (max-width: 35em) {

    }
}
</style>
