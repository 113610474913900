module.exports = function errorHandling (errors, type, { handleMessage, handleValidation, handleMode, handleInfo }) {
  const errorMap = {
    // --------------------------
    // Error handling for user to peak
    // --------------------------
    'userToPeak': {
      'BAD_PARAMS': {
        'message': 'Invalid user id',
      },
      'NOT_FOUND': {
        'message': 'User not found',
      },
      'REMOVED': {
        'message': 'User is removed!',
      },
    },
    // --------------------------
    // Error handling for submit friendships
    // --------------------------
    'friendships': {
      'INVALID_EMAIL_FILTER': {
        'message': 'Invalid email',
      },
    },
    // --------------------------
    // Error handling for submit friendships
    // --------------------------
    'submitFriendship': {
      'INVALID_USER_ID': {
        'message': 'Cannot send friend request for given user',
      },
      'REQUESTED_SELF': {
        'message': 'Cannot send friend request to self',
      },
      'USER_NOT_FOUND': {
        'message': 'User not found!',
      },
      'FRIENDSHIP_EXISTS': {
        'message': 'Friendship already exists with this user',
      },
      'FRIENDSHIP_WAITING': {
        'message': 'Already waiting for reaction of user',
      },
      'FRIENDSHIP_NOT_FOUND': {
        'message': 'Friend request not found',
      },
      'FRIENDSHIP_EXPIRED': {
        'message': 'Friend request expired',
      },
      'INVALID_STATUS': {
        'message': 'Invalid status',
      },
    },
    // --------------------------
    // Error for user management (login, register, recover, verify)
    // --------------------------
    'userManagement': {
      'NO_PASSWORD': {
        'message': 'Password required',
        'validate': [{ key: 'password', value: 'invalid'}, { key: 'email', value: 'none'}]
      },
      'NO_EMAIL': {
        'message': 'Email required',
        'validate': [{ key: 'password', value: 'none'}, { key: 'email', value: 'invalid'}]
      },
      'NO_VERIFICATION_TOKEN': {
        'message': 'Please use verification link from mail!',
        'validate': [{ key: 'password', value: 'none'}, { key: 'email', value: 'none'}],
        'goToMode': 'verify'
      },
      'EMAIL_IN_USE': {
        'message': 'Email already in use!',
        'validate': [{ key: 'password', value: 'none'}, { key: 'email', value: 'invalid'}],
      },
      'NOT_FOUNT': {
        'message': 'No user found with this email address!',
        'validate': [{ key: 'password', value: 'none'}, { key: 'email', value: 'invalid'}],
      },
      'INVALID_PASSWORD': {
        'message': 'Wrong email/password combination',
        'validate': [{ key: 'password', value: 'invalid'}, { key: 'email', value: 'invalid'}],
      },
      'INVALID_VERIFICATION_TOKEN': {
        'message': 'Verification token invalid, please request a new one!',
        'validate': [{ key: 'password', value: 'none'}, { key: 'email', value: 'none'}],
        'goToMode': 'verify'
      },
      'VERIFICATION_TOKEN_USED': {
        'message': 'Verification token already used to create user',
        'validate': [{ key: 'password', value: 'none'}, { key: 'email', value: 'none'}],
        'goToMode': 'login'
      },
      'SEND_EMAIL_ERROR': {
        'message': 'Unexpected error while sending the verification mail, please try again later!',
        'goToMode': 'login'
      },
      'VERIFICATION_TOKEN_ALREADY_SEND': {
        'message': 'Verification token already send, please check your inbox or try again in 5 minutes',
        'goToMode': 'login'
      },
      'RECOVER_TOKEN_ALREADY_SEND': {
        'message': 'Recover token already send, please check your inbox or try again in 5 minutes',
        'goToMode': 'login'
      },
      'MONGOOSE_ERROR': {
        'message': 'Invalid email address!',
        'validate': [{ key: 'password', value: 'none'}, { key: 'email', value: 'invalid'}],
      },
      'BRUTE_FORCE': {
        'message': 'To many verification requests, please verify your email first or try tomorrow!',
        'validate': [{ key: 'password', value: 'none'}, { key: 'email', value: 'invalid'}],
      },
      'USER_NOT_EXISTS': {
        'message': 'No user found with the given email address',
        'validate': [{ key: 'password', value: 'none'}, { key: 'email', value: 'invalid'}],
      },
      'NO_RECOVER_TOKEN': {
        'message': 'Please use recover link from mail!',
        'validate': [{ key: 'password', value: 'none'}, { key: 'email', value: 'none'}],
        'goToMode': 'recover'
      },
      'EXPIRED_VERIFICATION_TOKEN': {
        'message': 'Expired verification token, please request new one!',
        'validate': [{ key: 'password', value: 'none'}, { key: 'email', value: 'none'}],
        'goToMode': 'verify'
      },
      'EXPIRED_RECOVER_TOKEN': {
        'message': 'Expired recover token, please request new one!',
        'validate': [{ key: 'password', value: 'none'}, { key: 'email', value: 'none'}],
        'goToMode': 'recover'
      },
      'INVALID_RECOVER_TOKEN': {
        'message': 'Recover token invalid, please request a new one!',
        'validate': [{ key: 'password', value: 'none'}, { key: 'email', value: 'none'}],
        'goToMode': 'recover'
      },
      'NO_RECOVER_USER': {
        'message': 'No user found with received email!',
        'validate': [{ key: 'password', value: 'none'}, { key: 'email', value: 'none'}],
        'goToMode': 'login'
      },
    },
    // --------------------------
    // Error for update user profile
    // --------------------------
    'updateUserProfile': {
      'MISSING_PAYLOAD': {
        'message': 'Missing payload!',
      },
      'INVALID_ID': {
        'message': 'Invalid identifier!',
      },
      'NOT_FOUND': {
        'message': 'User not found',
      },
      'REMOVED': {
        'message': 'User is removed',
      },
      'UNAUTHORIZED': {
        'message': 'Unauthorized to update user',
      },
      'MISSING_NEW_PASSWORD': {
        'message': 'New password is required!',
        'validate': [{ key: 'password', value: 'valid'}, { key: 'newPassword', value: 'invalid'}, { key: 'confirmPassword', value: 'none'}],
      },
      'INVALID_PASSWORD': {
        'message': 'Current password is invalid!',
        'validate': [{ key: 'password', value: 'invalid'}, { key: 'newPassword', value: 'none'}, { key: 'confirmPassword', value: 'none'}],
      },
    },

    // --------------------------
    // Errors for Error page
    // --------------------------
    'errorPage': {
      'INVALID_CLAIM': {
        'title': 'Invalid claim',
        'description': 'You can only claim a recover of verification'
      },
      'INVALID_VERIFICATION_TOKEN': {
        'title': 'Invalid verification token',
        'description': 'You have tried to use a invalid verification token.'
      },
      'NO_VERIFICATION_TOKEN': {
        'title': 'No verification token',
        'description': 'We are sorry, but we didn\'t find a valid verification token.'
      },
      'EXPIRED_VERIFICATION_TOKEN': {
        'title': 'Expired verification token',
        'description': 'The token you tried to use is expired. Please request a new token.'
      },
      'VERIFICATION_TOKEN_USED': {
        'title': 'Verification token already used',
        'description': 'This token is already used for registering.'
      },
      'INVALID_RECOVER_TOKEN': {
        'title': 'Invalid recover token',
        'description': 'You have tried to use a invalid recover token.'
      },
      'NO_RECOVER_TOKEN': {
        'title': 'No recover token',
        'description': 'We are sorry, but we didn\'t find a valid recover token.'
      },
      'EXPIRED_RECOVER_TOKEN': {
        'title': 'Expired recover token',
        'description': 'The token you tried to use is expired. Please request a new token.'
      },
      'NO_RECOVER_USER': {
        'title': 'No user found',
        'description': 'No user found with received email.'
      },
    }
  }

  let handledError = false

  for (let error of errors) {
    if (errorMap[type]) {
      if (error.translation && errorMap[type][error.translation]) {
        if (handleMessage && errorMap[type][error.translation].message) handleMessage(errorMap[type][error.translation].message)
        if (handleValidation && errorMap[type][error.translation].validate) handleValidation(errorMap[type][error.translation].validate)
        if (handleMode && errorMap[type][error.translation].goToMode) handleMode(errorMap[type][error.translation].goToMode)
        if (handleInfo) handleInfo({ title: errorMap[type][error.translation].title || 'Something went wrong!', description: errorMap[type][error.translation].description || 'One of our developers noticed an unexpected error. We are sorry for the inconvenience! Please Try again later.' })
        handledError = true
      }
    }
  }

  if (!handledError && handleMessage) {
    handleMessage('Unexpected error occurred, please try again!')
  }

  if (!handledError && handleInfo) {
    handleInfo()
  }
}
