<template>
  <div class="page-content">
    <top-menu :user="user" :key="user ? `top-menu-${user._id}` : 'top-menu'"></top-menu>

    <el-row type="flex" justify="center" class="page-body friends-page">
      <el-col :xs="{span: 24}" :sm="{span: 24}" class="content-wrapper friends-row">
        <background v-if="user" :desktop="'friends-left'"></background>
        <background v-if="user" :desktop="'friends-right'"></background>

        <background></background>
        <friends-overview :user="user" :onEmit="onEmit"></friends-overview>

      </el-col>
    </el-row>

    <page-footer :key="'footer'"></page-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import TopMenu from '@/components/top-menu.vue'
import PageFooter from '@/components/page-footer.vue'

import Background from '@/components/background.vue'

import FriendsOverview from '@/components/friends-overview.vue'

export default {
  name: 'Friends',
  components: {
    TopMenu,
    PageFooter,

    Background,

    FriendsOverview
  },
  mounted () {
  },
  data () {
    return {
      user: this.$store.state.user
    }
  },
  methods: {
    onEmit (event) {
      // Events to listen to for update counts
      if (event === 'declinedFriendship') {
        this.user.counts.friends -= 1
        if (this.user.counts.friends < 0) this.user.counts.friends = 0
      }
      if (event === 'declinedOutgoingRequest') {
        this.user.counts.outgoingRequests -= 1
        if (this.user.counts.outgoingRequests < 0) this.user.counts.outgoingRequests = 0
      }
      if (event === 'declinedReceivedRequest') {
        this.user.counts.receivedRequests -= 1
        if (this.user.counts.receivedRequests < 0) this.user.counts.receivedRequests = 0
      }
      if (event === 'acceptReceivedRequest') {
        this.user.counts.receivedRequests -= 1
        this.user.counts.friends += 1
        if (this.user.counts.receivedRequests < 0) this.user.counts.receivedRequests = 0
      }
      if (event === 'newFriendRequest') {
        this.user.counts.outgoingRequests += 1
      }
    },

    addNewFriend () {

    }
  }
}
</script>
<style lang="scss">
  @import '../styles/variables.scss';

  .friends-page {
    min-height: 700px;

    .friends-row {
      margin-top: 150px;
      h3 {
        font-size: $font-size-large;
      }
    }

    .el-divider__text {
      color: $main-color-2;
      background-color: transparent;
      font-size: $font-size-large;
    }

    .el-divider {
      background-color: transparent;
    }

    .el-form-item__label {
      color: #c6bfbf;
    }
  }
</style>
