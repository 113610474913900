<template>
  <div class="page-footer">
    <el-row type="flex" justify="center">
      <el-col :xs="{span: 24}" :sm="{span: 24}" class="content-wrapper">

        <!-- Footer header -->
        <el-row type="flex" justify="center">
          <el-col :xs="{span: 24}" :sm="{span: 24}">
            <a href="/" class="clean-href">
              <h2>
                {{'anfient'.toUpperCase()}}
              </h2>
            </a>
          </el-col>
        </el-row>

        <!-- Contact -->
        <el-row type="flex" justify="left" class="hidden-sm-and-down">
          <el-col :xs="{span: 8, offset: 4}" :sm="{span: 8, offset: 4}">
            <div class="footer-contact">
              <h3>CONTACT</h3>
              <el-row type="flex" justify="left">
                <el-col :xs="{span: 12, offset: 0}" :sm="{span: 12, offset: 0}">
                  <p>
                    anfient.info@gmail.com
                  </p>
                </el-col>
              </el-row>

            </div>
          </el-col>

          <el-col :xs="{span: 8, offset: 4}" :sm="{span: 8, offset: 4}">
            <div class="footer-info">
              <h3>INFO</h3>
              <el-row type="flex" justify="left">
                <el-col :xs="{span: 12, offset: 0}" :sm="{span: 12, offset: 0}">
                  <p>
                    <router-link :to="'/terms-of-use'">Terms of use</router-link> <br>
                    <router-link :to="'/terms-of-service'">Terms of service</router-link><br>
                    <router-link :to="'/terms-and-conditions'">Terms and conditions</router-link><br>
                    <router-link :to="'/cookies-policy'">Cookies policy</router-link><br>
                  </p>
                </el-col>
                <el-col :xs="{span: 12, offset: 0}" :sm="{span: 12, offset: 0}">
                  <p>
                    <router-link :to="'/disclaimer'">Disclaimer</router-link><br>
                    <router-link :to="'/eula'">EULA</router-link><br>
                    <router-link :to="'/privacy-policy'">Privacy policy</router-link><br>
                  </p>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>

        <el-row type="flex" justify="left" class="hidden-md-and-up">
          <el-col :xs="{span: 20, offset: 2}">
            <el-row type="flex" justify="center">
              <div class="footer-contact">
                <h3>CONTACT</h3>
                <p>
                  anfient.info@gmail.com
                </p>

                <h3>INFO</h3>
                <p>
                  <router-link :to="'/terms-of-use'">Terms of use</router-link> <br>
                  <router-link :to="'/terms-of-service'">Terms of service</router-link><br>
                  <router-link :to="'/terms-and-conditions'">Terms and conditions</router-link><br>
                  <router-link :to="'/cookies-policy'">Cookies policy</router-link><br>
                  <router-link :to="'/disclaimer'">Disclaimer</router-link><br>
                  <router-link :to="'/eula'">EULA</router-link><br>
                  <router-link :to="'/privacy-policy'">Privacy policy</router-link><br>
                </p>

              </div>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'PageFooter',
  components: {
  },
  props: [],
  data () {
    return {
    }
  },

  methods: {
  }
}
</script>
<style lang="scss">
@import '../styles/variables.scss';

.page-footer {
  margin-top: 10em;

  background-color:  #141413;
  z-index:1000;
  font-size: $font-size-small-regular;

  border-top-width: 1px;
  border-top-color: $main-color-2;
  border-top-style: solid;

  p {
    line-height: 1.3;
  }

  h2 {
    color: #d6dddd;
  }

  .footer-contact {
    color: #d6dddd;
    text-align: left;
    font-size: $font-size-regular;
    line-height: 1em;

    p {
      line-height: 1.5;
      font-size: $font-size-regular
    }

    h3 {
      margin-bottom: 10px;
      font-size: $font-size-regular-large;
    }

    a {
      color: #d6dddd;
    }
  }

  .footer-info {
    color: #d6dddd;
    text-align: left;
    font-size: $font-size-regular;

    h3 {
      margin-bottom: 10px;
      font-size: $font-size-regular-large;
    }

    a {
      color: #d6dddd;
    }
  }

  @media screen and (max-width: 61em) {
      margin-top: 5em;

      .footer-contact {
        text-align: center;
      }
    }
}

</style>
