<template>
    <div class="login-card">
        <background :desktop="'login-card'"></background>

        <el-row type="flex" justify="center">
            <el-card class="box-card" style="width: 480px; margin-top: 4.5em;">
                <!---------------------------->
                <!-- LOGIN -->
                <!---------------------------->
                <span v-if="mode === 'login'">
                    <el-input placeholder="email" v-model="form.email" :class="'retro retro-prepend ' + validate.email">
                        <template slot="prepend"><i class="el-icon-user-solid"></i></template>
                    </el-input>

                    <el-input placeholder="password" v-model="form.password" show-password style="margin-top: 10px;" :class="'retro retro-prepend ' + validate.password" @keyup.enter.native="login">
                        <template slot="prepend"><i class="el-icon-key"></i></template>
                    </el-input>

                    <el-button type="primary" icon="el-icon-s-promotion" plain class="retro" style="width: 100%; margin-top: 20px;" @click="login">Login</el-button>

                    <el-button type="primary" icon="el-icon-star-off" plain class="retro" style="margin-left: 0px; margin-top: 1em; width: 100%;" @click="changeMode('verify')">New user</el-button>
                    <el-button type="text" class="retro recover" @click="changeMode('recover')">Forget password?</el-button>
                </span>

                <!---------------------------->
                <!-- VERIFY EMAIL -->
                <!---------------------------->
                <span v-if="mode === 'verify'">

                    <el-alert
                          title="Email verification"
                          description="A verification mail will be send to your email address (When it is not located in your inbox, check spam folder). The registration will proceed, after verification."
                          type="warning"
                          :closable="false"
                          style="margin-bottom: 10px;">
                    </el-alert>

                    <el-input placeholder="Email" v-model="form.email" :class="'retro retro-prepend ' + validate.email" @keyup.enter.native="verify">
                        <template slot="prepend">@</template>
                    </el-input>

                    <el-divider></el-divider>

                    <el-button type="primary" icon="el-icon-message" plain class="retro" style="width: 100%; margin-top: 0px;" @click="verify" :loading="submitting">Verify email</el-button>
                    <span></span>
                    <el-button type="primary" icon="el-icon-close" plain class="retro" style="width: 100%; margin-top: 10px;" @click="changeMode('login')">Cancel</el-button>
                </span>

                <!---------------------------->
                <!-- RECOVER PASSWORD -->
                <!---------------------------->
                <span v-if="mode === 'recover'">

                    <el-alert
                          title="Recovery email"
                          description="A email will be send to your linked email address with password recovery instructions."
                          type="warning"
                          :closable="false"
                          style="margin-bottom: 10px;">
                    </el-alert>

                    <el-input placeholder="Email" v-model="form.email" :class="'retro retro-prepend ' + validate.email" @keyup.enter.native="recover">
                        <template slot="prepend">@</template>
                    </el-input>

                    <el-divider></el-divider>

                    <el-button type="primary" icon="el-icon-message" plain class="retro" style="width: 100%; margin-top: 0px;" @click="recover" :loading="submitting">Recover password</el-button>
                    <span></span>
                    <el-button type="primary" icon="el-icon-close" plain class="retro" style="width: 100%; margin-top: 10px;" @click="changeMode('login')">Cancel</el-button>
                </span>

                <!---------------------------->
                <!-- RECOVERY -->
                <!---------------------------->
                <span v-if="mode === 'recovery'">

                    <el-alert
                            :title="form.email"
                            description="Password must be between 6 and 30 characters"
                            type="warning"
                            :closable="false"
                            style="margin-top: -15px; text-align: left">
                    </el-alert>

                    <el-input placeholder="New password" v-model="form.password" show-password style="margin-top: -5px;" :class="'retro retro-prepend ' + validate.password">
                        <template slot="prepend"><i class="el-icon-key"></i></template>
                    </el-input>

                    <el-input placeholder="Confirm password" v-model="form.confirmPassword" show-password style="margin-top: 10px;" :class="'retro retro-prepend ' + validate.confirmPassword" @keyup.enter.native="recoverPassword">
                        <template slot="prepend"><i class="el-icon-unlock"></i></template>
                    </el-input>

                    <el-divider></el-divider>

                    <el-button type="success" icon="el-icon-check" class="retro" plain style="width: 100%; margin-top: -20px;" @click="recoverPassword" :loading="submitting">Submit</el-button>
                    <span></span>
                    <el-button type="danger" icon="el-icon-close" class="retro" plain style="width: 100%; margin-top: 5px;" @click="changeMode('login')">Cancel</el-button>
                </span>

                <!---------------------------->
                <!-- REGISTER -->
                <!---------------------------->
                <span v-if="mode === 'register'">
                    <el-alert
                            :title="form.email"
                            description="Password must be between 6 and 30 characters"
                            type="warning"
                            :closable="false"
                            style="margin-top: -15px; text-align: left">
                    </el-alert>

                    <el-input placeholder="Password" v-model="form.password" show-password style="margin-top: -5px;" :class="'retro retro-prepend ' + validate.password">
                        <template slot="prepend"><i class="el-icon-key"></i></template>
                    </el-input>

                    <el-input placeholder="Confirm password" v-model="form.confirmPassword" show-password style="margin-top: 10px;" :class="'retro retro-prepend ' + validate.confirmPassword" @keyup.enter.native="register">
                        <template slot="prepend"><i class="el-icon-unlock"></i></template>
                    </el-input>

                    <el-divider></el-divider>

                    <el-button type="success" icon="el-icon-check" class="retro" plain style="width: 100%; margin-top: -20px;" @click="register" :loading="submitting">register</el-button>
                    <span></span>
                    <el-button type="danger" icon="el-icon-close" class="retro" plain style="width: 100%; margin-top: 5px;" @click="changeMode('login')">Cancel</el-button>
                </span>
            </el-card>
        </el-row>
    </div>
</template>

<script>
import ErrorHandling from '@/utils/error-handling.js'
import Background from '@/components/background.vue'

export default {
  name: 'LoginCard',
  components: { Background },
  props: ['onLogin'],
  mounted () {
    // Set mode
    this.setMode()
  },
  data () {
    return {
      form: {
        password: '',
        email: '',
        confirmPassword: '',
        token: ''
      },

      validate: {
        password: 'none',
        email: 'none',
        confirmPassword: 'none'
      },

      submitting: false,

      mode: 'profile'
    }
  },

  methods: {
    setMode () {
      // goto register mode
      if (this.$route.query.mode === 'register') {
        this.mode = 'register'
        this.form.email = this.$route.query.email
        this.form.token = this.$route.query.token

        this.validate.email = 'valid'
        return
      }

      // goto recovery mode
      if (this.$route.query.mode === 'recovery') {
        this.mode = 'recovery'
        this.form.email = this.$route.query.email
        this.form.token = this.$route.query.token

        this.validate.email = 'valid'
        return
      }

      // If no user, go to login page
      if (!this.user) {
        this.changeMode('login')
      }
    },

    changeMode (mode) {
      this.mode = mode

      this.refresh()
      this.$router.replace({ query: { mode: mode } })
    },

    refresh () {
      // Refresh all settings (form and validaitons)
      this.form.password = ''
      this.form.email = ''
      this.form.confirmPassword = ''

      this.validate.password = 'none'
      this.validate.email = 'none'
      this.validate.confirmPassword = 'none'

      this.submitting = false
    },

    // ---------------------------------------
    // Validations
    // ---------------------------------------
    handleValidation (obj) {
      const keys = Object.keys(obj)
      for (let key of keys) {
        this.validate[key] = obj[key]
      }
    },

    validateEmail () {
      if (!this.form.email) {
        this.validate.email = 'invalid'
        this.$message.error('Email required!')
        return false
      }

      if (!/\S+@\S+\.\S+/.test(this.form.email)) {
        this.validate.email = 'invalid'
        this.$message.error('Invalid email!')
        return false
      }

      this.validate.email = 'valid'

      return true
    },

    validatePassword () {
      if (!this.form.password) {
        this.validate.password = 'invalid'
        this.$message.error('Password required!')
        return false
      }

      if (this.form.password.length < 6) {
        this.validate.password = 'invalid'
        this.$message.error('Minimum Password length is 6 characters!')
        return false
      }

      if (this.form.password.length > 30) {
        this.validate.password = 'invalid'
        this.$message.error('Maximum Password length is 30 characters!')
        return false
      }

      this.validate.password = 'valid'
      return true
    },

    validateConfirmPassword () {
      if (this.form.password !== this.form.confirmPassword) {
        this.validate.password = 'none'
        this.validate.confirmPassword = 'invalid'
        this.$message.error('Confirmation password does not equal password!')
        return false
      }

      this.validate.confirmPassword = 'valid'
      return true
    },

    // ---------------------------------------
    // Endpoints 1/2 (Happy flow)
    // ---------------------------------------
    async verify () {
      if (this.submitting) {
        this.$message.error('Already submitting, please wait!')
        return
      }
      const isValid = this.validateEmail()
      if (!isValid) return

      this.submitting = true

      const res = await this.$http.post('auth/local/verify', this.form)
      if (res.errors && res.errors.length) {
        ErrorHandling(res.errors, 'userManagement', { handleMessage: this.$message.error, handleValidation: this.handleValidation, handleMode: this.changeMode })
        this.submitting = false
      } else {
        this.$message.success('Verification email send!')
        this.submitting = false
        this.changeMode('login')
      }
    },

    async recover () {
      if (this.submitting) {
        this.$message.error('Already submitting, please wait!')
        return
      }
      const isValid = this.validateEmail()
      if (!isValid) return

      this.submitting = true

      const res = await this.$http.post('auth/local/recover', this.form)
      if (res.errors && res.errors.length) {
        ErrorHandling(res.errors, 'userManagement', { handleMessage: this.$message.error, handleValidation: this.handleValidation, handleMode: this.changeMode })
        this.submitting = false
      } else {
        this.$message.success('Recover email send!')
        this.submitting = false
        this.changeMode('login')
      }
    },

    async recoverPassword () {
      if (this.submitting) {
        this.$message.error('Already submitting, please wait!')
        return
      }
      const isValidPassword = this.validatePassword()
      const isValid = isValidPassword && this.validateConfirmPassword()
      if (!isValid) return

      this.submitting = true

      const res = await this.$http.post('auth/local/recover-password', this.form)
      if (res.errors && res.errors.length) {
        ErrorHandling(res.errors, 'userManagement', { handleMessage: this.$message.error, handleValidation: this.handleValidation, handleMode: this.changeMode })
        this.submitting = false
      } else {
        this.submitting = false
        this.$message.success('Recovery completed, please login with your new password!')
        this.changeMode('login')
      }
    },

    async register () {
      if (this.submitting) {
        this.$message.error('Already submitting, please wait!')
        return
      }
      const isValidPassword = this.validatePassword()
      const isValid = isValidPassword && this.validateConfirmPassword()
      if (!isValid) return

      this.submitting = true

      const res = await this.$http.post('auth/local/register', this.form)
      if (res.errors && res.errors.length) {
        ErrorHandling(res.errors, 'userManagement', { handleMessage: this.$message.error, handleValidation: this.handleValidation, handleMode: this.changeMode })
        this.submitting = false
      } else {
        this.submitting = false
        this.$message.success('Registration completed, please login!')
        this.changeMode('login')
      }
    },

    async login () {
      if (this.submitting) {
        this.$message.error('Already submitting, please wait!')
        return
      }
      const isValidEmail = this.validateEmail()
      const isValid = isValidEmail && this.validatePassword()
      if (!isValid) return

      this.submitting = true

      const res = await this.$http.post('auth/local/login', this.form)

      if (res.errors && res.errors.length) {
        ErrorHandling(res.errors, 'userManagement', { handleMessage: this.$message.error, handleValidation: this.handleValidation, handleMode: this.changeMode })
        this.submitting = false
      }

      else {
        this.submitting = false
        this.onLogin(res.list[0])
      }
    }
  }
}
</script>

<style lang="scss">
@import '../styles/variables.scss';

.login-card {
    .el-divider {
        background-color: transparent;
    }

    .box-card.el-card {
        border: 0px solid $main-color-2;
        background-color: #fff0;
    }

}
</style>
