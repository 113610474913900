<template>
    <div class="friends-dialog">
        <el-dialog :title="'Send friend request'"
                   :visible.sync="showDialog"
                   class="friends-details-dialog"
                   :before-close="onClose"
                   center>
            <background :desktop="'friends-small'"></background>
            <background :desktop="'default-small'"></background>

            <el-row type="flex" justify="center" style="position: relative" class="dialog-body-content dialog-item">

                <el-col :xs="{span: 12}" :sm="{span: 12}">


                    <el-input
                            placeholder='Fill in email, like: "user@example.com"'
                            v-model="email"
                            v-if="status !== 'done'"
                            clearable
                            :class="'retro retro-search-friends '" :disabled="['searching', 'loading'].includes(status)"
                            @keyup.enter.native="sendFriendRequest">
                        <i slot="prefix" class="el-input__icon el-icon-loading" v-show="['searching', 'loading'].includes(status)"></i>
                    </el-input>
                    <p v-if="status === 'done'"> Friend request send! We only have to wait till user accept it...</p>
                </el-col>
            </el-row>

            <span slot="footer" class="dialog-footer">
                <el-button type="danger" v-if="status !== 'done'" plain class="retro action-button dialog-item" @click="onClose">Cancel</el-button>
                <el-button type="primary" v-if="status !== 'done'" :loading="['searching', 'loading'].includes(status)" plain class="retro action-button dialog-item" :disabled="!email" @click="sendFriendRequest">Send friend request</el-button>
                <el-button type="primary" v-if="status === 'done'" plain class="retro action-button dialog-item" :disabled="!email" @click="onClose(true)">Oke :)!</el-button>
              </span>
        </el-dialog>
    </div>
</template>

<script>
import Background from '@/components/background.vue'
import ErrorHandling from '@/utils/error-handling.js'
import FriendsTile from '@/components/friends-tile.vue'

export default {
  name: 'FriendsDialog',
  components: { Background, FriendsTile },
  props: [ 'onClose' ],
  mounted () {},
  data () {
    return {
      showDialog: true,
      email: '',
      status: 'none',
      searchResults: []
    }
  },

  methods: {
    async sendFriendRequest () {
      if (['loading', 'searching'].includes(this.status)) {
        this.$message.error('Already submitting, please wait!')
        return
      }

      if (!this.email) {
        this.$message.error('No email given...')
        return
      }

      this.status = 'loading'

      const res = await this.$http.post(`friendships`, { email: this.email })
      if (res.errors && res.errors.length) {
        ErrorHandling(res.errors, 'submitFriendship', { handleMessage: this.$message.error })
      } else {
        this.status = 'done'
        this.$message.success('Friend request send!')
        if (this.onEmit) this.onEmit('newFriendRequest')
      }
    },
  }
}
</script>

<style lang="scss">
    @import '../styles/variables.scss';

    .friends-dialog {
        .dialog-footer {
            z-index: 10 !important;
        }

        .dialog-item {
            z-index: 10 !important;
        }

        .el-dialog__title {
            z-index: 10 !important;
        }

        .dialog-body-content {
            min-height: 520px;
        }

        .retro-search-friends {
            color: #3B352F;
            border-radius: 25px;
            border: 4px solid #736b65;
            background-color: #736b65;
            font-family: "Nunito Sans", sans-serif;
        }

        .retro-search-friends .el-input__inner {
            color: #ffffff;
            border-radius: 25px;
            border: 4px solid #736b65;
            background-color: #736b65;
        }

        .search-info {
            text-align: center;
        }

        .el-dialog{
            width: 60%;
            background-color: $main-color-1;
            color: $main-color-2;
            border-radius: 25px;
            border-style: solid;
            border-color: rgba(255, 255, 255, 0.10);

            .el-dialog__title {
                color: $main-color-2;
            }
        }

        .system-image-details {
            z-index: 2;
            max-width: 600px;
        }

        @media screen and (max-width: 61em) {
            .el-dialog {
                width: 80%;
            }
        }

        @media screen and (max-width: 35em) {
            .el-dialog {
                width: 90%;
            }
        }
    }
</style>
