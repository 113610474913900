<template>
    <div class="background">
        <div v-if="overflow" class="background-overflow">
        </div>

        <!-- Background image for desktop -->
        <el-image v-if="desktop && backgrounds.desktop[desktop]"
                :src="backgrounds.desktop[desktop]"
                :class="`hidden-md-and-down background-image background-desktop-image background-${desktop}-image`"
                v-show="loadedBackgrounds.desktop[desktop]"
                @load="onImgLoad('desktop', desktop)"
        ></el-image>

        <!-- Background image for tablet -->
        <el-image v-if="tablet && backgrounds.tablet[tablet]"
                  :src="backgrounds.tablet[tablet]"
                  :class="`hidden-sm-and-down hidden-lg-and-up background-image background-tablet-image background-${tablet}-image`"
                  v-show="loadedBackgrounds.tablet[tablet]"
                  @load="onImgLoad('tablet', tablet)"
        ></el-image>

        <!-- Background image for mobile -->
        <el-image v-if="mobile && backgrounds.mobile[mobile]"
                  :src="backgrounds.mobile[mobile]"
                  :class="`hidden-md-and-up background-image background-mobile-image background-${mobile}-image`"
                  v-show="loadedBackgrounds.mobile[mobile]"
                  @load="onImgLoad('mobile', mobile)"
        ></el-image>
    </div>
</template>

<script>
export default {
  name: 'Background',
  components: {},
  props: {
    overflow: {
      default: false
    },
    desktop: {
      default: 'default'
    },
    tablet: {
      default: 'default'
    },
    mobile: {
      default: 'default'
    }
  },
  mounted () {},
  data () {
    return {
      backgrounds: {
        /* Backgrounds for desktop */
        desktop: {
          default: require('@/assets/background-desktop-default.png'),
          party: require('@/assets/background-desktop-party.png'),
          'env1': require('@/assets/background-desctop-env1.png'),
          'left-party': require('@/assets/background-desktop-left-party.png'),
          'right-party': require('@/assets/background-desktop-right-party.png'),
          'friends-left': require('@/assets/background-desktop-friends-left.png'),
          'friends-right': require('@/assets/background-desktop-friends-right.png'),
          'login-card': require('@/assets/background-login-card.png'),
          'overflow': require('@/assets/background-overflow.png'),
          'default-small': require('@/assets/background-desktop-default-small.png'),
          'friends-small': require('@/assets/background-desktop-friends-small.png')
        },

        /* Backgrounds for tablet */
        tablet: {
        },

        /* Backgrounds for mobile */
        mobile: {
        }
      },

      loadedBackgrounds: {
        /* Backgrounds for desktop */
        desktop: {
          default: false,
          party: false,
          'left-party': false,
          'right-party': false,
          'login-card': false,
          'overflow': false,
          'env1': false,
          'friends-left': false,
          'friends-right': false,
          'default-small': false,
          'friends-small': false
        },

        /* Backgrounds for tablet */
        tablet: {
        },

        /* Backgrounds for mobile */
        mobile: {
        }
      }
    }
  },

  methods: {
    onImgLoad (size, name) {
      if (!this.loadedBackgrounds[size]) return
      if (!(name in this.loadedBackgrounds[size])) return
      this.loadedBackgrounds[size][name] = true
    }
  }
}
</script>

<style lang="scss">
@import '../styles/variables.scss';

.background {
    .background-image {
        position: absolute;
        z-index:-100;
    }

    .background-desktop-image {
        position: absolute;
        width: 1786px;
        height: 745px;
        margin-top: -10em;
        margin-left: -59.5em;
        opacity: 1;
    }

    .background-tablet-image {
        position: fixed;
        width: 1024px;
        height: 768px;
        margin-top: -10em;
        margin-left: -30em;
        opacity: 0.5;
    }

    .background-mobile-image {
        position: fixed;
        width: 414px;
        height: 736px;
        margin-top: -13em;
        margin-left: -12em;
        opacity: 0.5;
    }

    .background-overflow {
        position: absolute;
        width: 100%;
        height: 150%;
        opacity: 1;
        z-index:-91;
        left: 0;
        top: 0;
        background-image: url('../assets/background-overflow.png');
        background-repeat: repeat;
    }

    .background-login-card-image {
        position: absolute;
        width: 656px;
        height: 505px;
        margin-top: -1.6em;
        margin-left: -20.5em;
        opacity: 1;
        z-index:-90;
    }

    .background-env1-image {
        position: absolute;
        width: 1208px;
        height: 729px;
        margin-top: -4em;
        margin-left: -35em;
        z-index:-90;
    }

    .background-left-party-image {
        position: absolute;
        width: 656px;
        height: 620px;
        margin-top: -5em;
        margin-left: -55em;
        z-index:-93;
    }

    .background-right-party-image {
        position: absolute;
        width: 656px;
        height: 620px;
        margin-top: -5em;
        margin-left: 15em;
        z-index:-93;
    }

    .background-friends-left-image {
        position: absolute;
        width: 621px;
        height: 623px;
        margin-top: 0em;
        margin-left: 0em;
        opacity: 0.5;
        z-index:-93;
    }

    .background-friends-right-image {
        position: absolute;
        width: 621px;
        height: 623px;
        margin-top: 0em;
        margin-left: -35em;
        opacity: 0.5;
        z-index:-93;
    }

    .background-system-tile-image {
        position: relative;
        z-index: -1;
        margin-top: -400px;
        width: 100%;
        height: auto;
        margin-left: 0em;
        opacity: 1;
    }

    .background-default-small-image {
        position: absolute;
        z-index:0;
        width: 1007px;
        height: 520px;
        margin-left: 0em;
        margin-top: 1em;
        opacity: 1;
    }

    .background-friends-small-image {
        position: absolute;
        z-index: 1;
        width: 1007px;
        height: 520px;
        margin-left: 0em;
        margin-top: 1em;
        opacity: 1;
    }

    .background-system-details-image {
        margin-left: -7em;
        margin-right: 0px;
        margin-top: -2em;
        position: relative;
        z-index: 1;
        width: 150%;
        max-width: 900px;
        height: auto;
        opacity: 1;
    }
    .background-system-details-image.background-mobile-image {
        margin-left: -6em;
        margin-top: -1em;
    }
    .background-system-details-image.background-tablet-image {
        margin-left: -6em;
        margin-top: -1em;
    }

    @media screen and (max-width: 28em) {
        .background-system-details-image.background-mobile-image {
            margin-left: -2em;
            margin-top: -1em;
        }
    }
}
</style>
