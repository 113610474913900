<template>
    <div class="friends-overview">
        <el-row justify="center">
            <el-col :xs="{span: 24}" :sm="{span: 24}">
                <el-row justify="end" class="sub-bar">
                    <el-col :xs="{span: 18}" :sm="{span: 18}">
                        <div style="height: 1px;"></div>
                    </el-col>
                    <el-col :xs="{span: 2}" :sm="{span: 2}">
                        <el-image class="clickable" :src="addUserIcon"
                                  v-show="addUserIconLoaded"
                                  @load="onImgLoad()"
                                  :class="`add-user-icon`"
                                  @click="showFriendsDialog = true"
                        ></el-image>
                    </el-col>
                    <el-col :xs="{span: 4}" :sm="{span: 4}">
                        <el-input
                                :placeholder="'Name/email filter...'"
                                v-model="filter"
                                @input="textChange"
                                clearable
                                size="mini"
                                prefix-icon="el-icon-search"
                                :class="'retro retro-search-friends '" :disabled="['searching', 'loading'].includes(status)"
                                @keyup.enter.native="search">
                            <i slot="prefix" class="el-input__icon el-icon-loading" v-show="['searching', 'loading'].includes(status)"></i>
                        </el-input>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

        <!---------------------------->
        <!--  (MODE: FRIENDS) -->
        <!---------------------------->
        <el-row type="flex" justify="center">
            <!---------------------------->
            <!-- (MODE: FRIENDS) Show loading default loading -->
            <!---------------------------->
            <div class="search-info" v-if="['searching', 'loading'].includes(status)">
                <i class="el-icon-loading"></i> Loading friends...
            </div>

            <!---------------------------->
            <!-- (MODE: FRIENDS) Show no friends -->
            <!---------------------------->
            <div class="search-info" v-if="status === 'done' && !friends.length">
                <i class="el-icon-user-solid"></i> No friends yet :)
            </div>

            <!---------------------------->
            <!-- (MODE: FRIENDS) Show no results -->
            <!---------------------------->
            <div class="search-info" v-if="status === 'done' && friends.length && filter && !searchResults.length">
                <i class="el-icon-user-solid"></i> No matching friends...
            </div>
        </el-row>

        <!---------------------------->
        <!-- Show results -->
        <!---------------------------->
        <el-row justify="left" style="margin: 25px; width: 100%;" v-if="status === 'done' && searchResults.length">
            <el-col :xs="{span: 24}" :sm="{span: 12}" :md="{span: 8}" v-for="el of searchResults" :key="el._id">
                <div style="height: 1px;"></div>
                <friends-tile :friend="el" :user="user" :addFriend="addFriend" :removeFriend="removeFriend" :onEmit="onEmit"></friends-tile>
            </el-col>
        </el-row>

        <friends-dialog v-if="showFriendsDialog" :onClose="closeFriendsDialog"></friends-dialog>
    </div>
</template>

<script>
// @ is an alias to /src
import chunkArray from '@/utils/chunk-array.js'
import FriendsTile from '@/components/friends-tile.vue'
import FriendsDialog from '@/components/friends-dialog.vue'

import ErrorHandling from '@/utils/error-handling.js'

export default {
  name: 'FriendsOverview',
  components: {
    FriendsTile,
    FriendsDialog
  },
  props: ['user', 'onEmit'],
  mounted () {
    // Get friends
    this.getFriends()

    // Add sockets events
    this.socketEvents()
  },
  data () {
    return {
      pendingRequests: true,
      receivedRequests: true,
      acceptedRequests: true,

      friends: [],

      filter: '',
      searchResults: [],

      status: 'none',

      addUserIcon: require(`@/assets/add-user-icon.png`),
      addUserIconLoaded: false,

      showFriendsDialog: false
    }
  },

  methods: {
    isEven (value) {
      return value % 2 === 0
    },

    to (name) {
      this.$router.push({ name: name })
    },

    chunkArray,

    onImgLoad () {
      this.addUserIconLoaded = true
    },

    socketEvents () {
      let self = this
      // ---------------------------------------
      // Online/Offline status
      // ---------------------------------------
      this.$socket.on('incomingMember', function (data) {
        for (let friend of self.friends) {
          if (friend._id === data.userId) {
            friend.currentStatus = 'online'
          }
        }
      })

      this.$socket.on('leavingMember', function (data) {
        for (let friend of self.friends) {
          if (friend._id === data.userId) {
            friend.currentStatus = 'offline'
          }
        }
      })

      // ---------------------------------------
      // Friendship status
      // ---------------------------------------
      this.$socket.on('newFriendRequest', function (data) {
        self.friends.push(data.fromUser)
      })

      this.$socket.on('acceptFriendRequest', function (data) {
        for (let friend of self.friends) {
          if (friend._id === data.fromUser._id) {
            friend.status = 'accepted'
          }
        }
      })

      this.$socket.on('denyFriendRequest', function (data) {
        for (let friend of self.friends) {
          if (friend._id === data.fromUser._id) {
            friend.status = 'decline'
          }
        }
      })
    },

    textChange () {
        this.filterFriends(this.filter)
        this.status = 'done'
    },

    filterFriends (filter = '') {
      this.searchResults = []

      for (let friend of this.friends) {
        if (!this.pendingRequests && friend.status === 'waiting') continue
        if (!this.receivedRequests && friend.status === 'pending') continue
        if (!this.acceptedRequests && friend.status === 'accepted') continue

        let match = filter.trim().toLowerCase()

        if (!filter) {
          this.searchResults.push(friend)
          continue
        }

        const friendName = (friend.username || 'anonymous').trim().toLowerCase()
        const friendEmail = friend.email.trim().toLowerCase()

        if (friendName.includes(match) || friendEmail.includes(match)) {
          this.searchResults.push(friend)
        }
      }
    },
    closeFriendsDialog () {
        this.showFriendsDialog = false
    },

    addFriend (user) {
      if (this.friends.find(el => el._id === user._id)) return

      this.friends.push(user)
    },

    removeFriend (user) {
      this.friends = this.friends.filter(el => el._id !== user._id)
    },

    // ---------------------------------------
    // Endpoints 1/2 (Happy flow)
    // ---------------------------------------
    async getFriends () {
      this.status = 'loading'

      const res = await this.$http.get(`friendships`)
      if (res.errors && res.errors.length) {
        ErrorHandling(res.errors, 'friendships', { handleMessage: this.$message.error })
        this.status = 'done'
      } else {
        this.friends = res.list
        this.searchResults = res.list
        this.status = 'done'
      }
    },

    search () {
      if (this.filter.trim()) {
        this.filterFriends(this.filter)
        this.status = 'done'
      } else {
        this.filter = ''
        this.searchResults = []
        this.status = 'done'
      }
    }
  }
}
</script>

<style lang="scss">
@import '../styles/variables.scss';

.friends-overview {
    .sub-bar {
        margin-top: -110px;
        height: 60px;
        background-color: #3f3c3875;
        border-radius: 20px;

        .add-user-icon {
            width: 24px;
            height: 24px;
            margin-top: 20px;
        }

        .retro-search-friends {
            margin-top: 14px;
            margin-left: -35px;
            font-size:0.7em;
            color: #3B352F;
            border-radius: 25px;
            border: 4px solid #736b65;
            background-color: #736b65;
            font-family: "Nunito Sans", sans-serif;
            width: 200px;
        }

        .retro-search-friends .el-input__inner {
            font-size:1em;
            color: #ffffff;
            border-radius: 25px;
            border: 4px solid #736b65;
            background-color: #736b65;
        }
    }

    .search-info {
        margin-top: 50px;
        text-align: left;
        max-width: 500px;
    }

    .retro .el-input__inner {
        border-left-width: 1px;
    }

    .retro-search {
        width: 50%;
    }

    @media screen and (max-width: 61em) {
        .retro-search {
            width: 80%;
        }
    }
}
</style>
