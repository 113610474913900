<template>
  <div class="page-content">
    <top-menu :user="user" :key="user ? `top-menu-${user._id}` : 'top-menu'"></top-menu>

    <el-row type="flex" justify="center" class="page-body home-page">
      <el-col :xs="{span: 24}" :sm="{span: 24}" class="content-wrapper home-row">

        <background v-if="user" :desktop="'env1'"></background>

        <background></background>

        <!---------------------------->
        <!-- If user, show profile -->
        <!---------------------------->
        <el-row type="flex" justify="left" v-if="user">
          <character-form :user="user" ></character-form>
        </el-row>

      </el-col>
    </el-row>

    <page-footer :key="'footer'"></page-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import TopMenu from '@/components/top-menu.vue'
import PageFooter from '@/components/page-footer.vue'

import Background from '@/components/background.vue'

import LoginCard from '@/components/login-card.vue'

import CharacterForm from '@/components/character-form.vue'

export default {
  name: 'Home',
  components: {
    TopMenu,
    PageFooter,

    Background,

    LoginCard,

    CharacterForm,
  },
  mounted () {
  },
  data () {
    return {
      user: this.$store.state.user,
    }
  },
  methods: {
  }
}
</script>
<style lang="scss">
  @import '../styles/variables.scss';

  .home-page {
    min-height: 700px;
    .el-divider__text {
      color: $main-color-2;
      background-color: transparent;
      font-size: $font-size-large;
    }

    .el-divider {
      background-color: transparent;
    }

    .el-form-item__label {
      color: #c6bfbf;
    }
  }
</style>
