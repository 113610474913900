<template>
  <div class="page-content">
      <top-menu :setUser="setUser" :user="user" :key="user ? `top-menu-${user._id}` : 'top-menu'"></top-menu>

    <el-row type="flex" justify="center" class="page-body error-page">

        <el-col :xs="{span: 20}" :sm="{span: 20}" class="content-wrapper error-row">

            <background></background>

            <!---------------------------->
            <!-- Show error message -->
            <!---------------------------->
            <el-row type="flex" justify="center">
              <el-alert
                      :title="title"
                      :description="description"
                      type="info"
                      :closable="false"
                      style="margin-top: 150px; max-width: 500px;">
              </el-alert>
            </el-row>
        </el-col>
    </el-row>

      <page-footer :key="'footer'"></page-footer>
  </div>
</template>

<script>
// @ is an alias to /src
import TopMenu from '@/components/top-menu.vue'
import ErrorHandling from '@/utils/error-handling.js'
import PageFooter from '@/components/page-footer.vue'
import Background from '@/components/background.vue'

export default {
  name: 'Error',
  components: {
    TopMenu,
    PageFooter,
    Background
  },
  mounted () {
    this.fromatError()
  },
  data () {
    return {
      user: this.$store.state.user,

      errorCode: this.$route.query.code,
      errorTranslation: this.$route.query.translation,

      title: 'Something went wrong!',
      description: 'One of our developers noticed an unexpected error. We are sorry for the inconvenience! Please Try again later.'
    }
  },
  methods: {
    setUser (user) {
      if (user) {
        this.$store.commit('user', user)
        this.user = user
      } else {
        this.$store.commit('user', false)
        this.user = false
        this.$router.push({ name: 'home', query: { mode: 'login' } })
      }
    },
    fromatError () {
      let self = this
      console.log(this.errorTranslation)
      ErrorHandling([{ translation: this.errorTranslation }], 'errorPage', { handleInfo: (info) => {
        if (info && info.title && info.description) {
          self.title = info.title
          self.description = info.description
        }
      } })
    }
  }
}
</script>
<style lang="scss">
  @import '../styles/variables.scss';

  .error-page {

  }

</style>
